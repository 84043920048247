import React from 'react';

type LabelProps = {
  htmlFor?: string;
  text: string;
  className?: string;
};

export const Label: React.FC<LabelProps> = ({
  htmlFor,
  text,
  className = '',
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`block font-medium text-gray-700 ${className}`}
    >
      {text}
    </label>
  );
};
