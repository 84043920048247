import { Button, Input, Label } from '@/components/atoms';

export const BullhornCredentials: React.FC = () => {
  const isVerified = false;

  const renderVerifyandPassword = () => {
    return (
      <>
        <p
          className={`text-xs ml-2 mt-0 border rounded-2xl py-1 px-2 ${
            isVerified ? 'text-green-500' : 'text-red-500'
          }`}
        >
          {isVerified ? (
            <>
              <i className="fa-solid fa-check"></i> Verified
            </>
          ) : (
            <>
              <i className="fa-solid fa-triangle-exclamation mr-1"></i> Not
              Verified
            </>
          )}
        </p>
      </>
    );
  };

  return (
    <div className="flex-1 mb-5">
      <div className="flex items-center mb-3">
        <h3 className=" md:text-2xs md:font-semibold">Account Credentials</h3>
        {renderVerifyandPassword()}
      </div>
      <form className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <div className="md:col-span-1">
          <Label htmlFor={'username'} text={'Client Username'} />
          <Input
            type="text"
            placeholder="Client Username"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="md:col-span-1">
          <Label htmlFor={'password'} text={'Client Password'} />
          <Input
            type="password"
            placeholder="Client Password"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="md:col-span-1">
          <Label htmlFor={'id'} text={'Client ID'} />
          <Input
            type="password"
            placeholder="Client ID"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="md:col-span-1">
          <Label htmlFor={'secret'} text={'Client Secret'} />
          <Input
            type="password"
            placeholder="Client Secret"
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
      </form>
      <div className="flex items-start mt-5">
        <Button
          className="flex h-10 w-[150px] items-center justify-center rounded-md bg-blue-800 px-5 text-center text-sm font-medium text-white disabled:cursor-not-allowed disabled:opacity-70"
          text={'Verify'}
        />
      </div>
    </div>
  );
};
