import { Input, Label } from '@/components/atoms';

export const BullhornChecklist: React.FC = () => {
  const isVerified = true;
  const isIntegrationActivated = false;
  return (
    <>
      <div className="flex items-center mb-3">
        <Label
          className="mr-2 font-semibold"
          text={'Update Checklist status in Bullhorn'}
        />

        <div
          className={`relative w-6 h-3 transition-colors duration-200 ease-in-out rounded-full cursor-pointer ${
            isIntegrationActivated ? 'bg-blue-500' : 'bg-gray-300'
          }`}
        >
          <Input
            type={'checkbox'}
            disabled={isVerified ? false : true}
            checked={isIntegrationActivated}
            className="opacity-0 absolute w-0 h-0"
          />
          <div
            className={`absolute left-0 top-0 w-3 h-3 transition-transform duration-200 ease-in-out transform ${
              isIntegrationActivated ? 'translate-x-full' : 'translate-x-0'
            } bg-white rounded-full`}
          ></div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4 mb-3 mt-6">
        <div className="md:flex-1">
          <Label htmlFor="username" text="In Progress" />
          <Input
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            type="text"
            placeholder="In Progress"
          />
        </div>
        <div className="md:flex-1">
          <Label text="Compeleted" />
          <Input
            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
            type="text"
            placeholder="Completed"
          />
        </div>
      </div>
      <div className="text-sm text-">
        <strong>
          You need to add these field values to the Value List of the selected
          Bullhorn Candidate as well.
        </strong>
      </div>
    </>
  );
};
