import React from 'react';

interface ButtonProps {
  onClick?: () => void;
  text?: React.ReactNode;
  icon?: string;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  icon,
  className,
  type = 'button',
}) => {
  return (
    <button type={type} onClick={onClick} className={className}>
      {icon && <i className={icon}></i>}
      {text}
    </button>
  );
};
