import APIClient from '@/config';
import { AgencyResponse } from '@/utils/interface';

const handleRequestError = (error: Error) => {
  throw new Error(`Request failed: ${error.message}`);
};

export async function getAgency(
  accessToken: string,
): Promise<AgencyResponse | undefined> {
  const url = '/agencies';
  const headers = { Authorization: `Bearer ${accessToken}` };

  try {
    const response = await APIClient.get<{ data: AgencyResponse[] }>(url, {
      headers,
    });

    const agencies = response.data?.data;
    if (!agencies || agencies.length === 0) {
      throw new Error('No agency data found');
    }

    return agencies[0];
  } catch (error) {
    handleRequestError(error as Error);
  }
}
