export enum UserRole {
  AGENCY = 'agency',
  ATS = 'ats',
}

export type NotificationProps = {
  type: 'success' | 'error';
  title?: React.ReactNode;
  description?: React.ReactNode;
};
