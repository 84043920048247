import React, { useState } from 'react';

import { IntegrationLayoutProps } from '@/utils/interface';

import Navbar, {
  NavigationItem,
} from '@/components/organisms/navigation/Navbar';
import { MobileHeader } from '@/components/atoms';

const IntegrationPageLayout: React.FC<IntegrationLayoutProps> = ({
  children,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const integrationNavigationOptions: NavigationItem[] = [
    {
      name: 'Bullhorn',
      href: '/bullhorn',
      icon: '/assets/bullhornLogo.webp',
      current: location.pathname.startsWith('/bullhorn'),
    },
  ];

  return (
    <div className="flex min-h-screen">
      <Navbar
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        navigationOptions={integrationNavigationOptions}
      />

      <div className="flex flex-1 overflow-hidden bg-[#f4f6fd] justify-center items-stretch ">
        <div className="pb-8">
          <div className="mb-5">
            <MobileHeader setMobileMenuOpen={setMobileMenuOpen} />
          </div>

          <div className="flex flex-col md:flex-row md:space-x-10 md:ml-4 p-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationPageLayout;
