import React from 'react';
import { Toaster } from 'react-hot-toast';
import AppRouter from './routes/appRouter';
import { AgencyProvider } from './hooks/useAgency';

const App: React.FC = () => {
  return (
    <AgencyProvider>
      <div className="App">
        <Toaster position="top-right" />
        <AppRouter />
      </div>
    </AgencyProvider>
  );
};

export default App;
