import React from 'react';
import { ProfileMenu } from '../profile-menu/ProfileMenu';

interface Props {
  setMobileMenuOpen: (value: boolean) => void;
  hideNavbar?: boolean;
  navigation?: {
    name: string;
    href: string;
  }[];
}

export const MobileHeader: React.FC<Props> = ({
  setMobileMenuOpen,
  hideNavbar = false,
}) => {
  return (
    <header className="noprint w-full">
      <div className="relative z-10 flex pr-5 flex-shrink-0 border-b border-gray-200 shadow-sm md:border-none md:shadow-none">
        {!hideNavbar && (
          <button
            type="button"
            className="border-r w-[70px] border-gray-200 bg-custom-blue px-4 text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <i className="fa-solid fa-bars "></i>
          </button>
        )}
        <ProfileMenu />
      </div>
    </header>
  );
};
