import APIClient from '@/config';
import { AuthCredentials } from '@/utils/interface';
import { AxiosResponse } from 'axios';

export async function handleAuthAgency(
  email: string,
  password: string,
): Promise<AuthCredentials> {
  try {
    const response: AxiosResponse<AuthCredentials> =
      await APIClient.post<AuthCredentials>('/auth', {
        email,
        password,
      });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function handleAuthATS(
  email: string,
  password: string,
  accessToken: string,
): Promise<AuthCredentials> {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response: AxiosResponse<AuthCredentials> =
      await APIClient.post<AuthCredentials>(
        '/agencies/setup',
        { email, password },
        { headers },
      );

    return response.data;
  } catch (error) {
    throw error;
  }
}
