import { NotificationProps } from '@/utils/types';

export const LOGIN_SUCCESS: NotificationProps = {
  type: 'success',
  title: 'Login Successful',
  description: 'Welcome back! You have successfully logged in.',
};

export const LOGOUT_SUCCESS: NotificationProps = {
  type: 'success',
  title: 'Logout Successful',
  description: 'You have successfully logged out.',
};

export const INCORRECT_LOGIN: NotificationProps = {
  type: 'error',
  title: 'Incorrect Login',
  description:
    'We apologize, but your login credentials are incorrect. Please review your username and password and try again.',
};

export const GENERIC_ERROR: NotificationProps = {
  type: 'error',
  title: 'Something Went Wrong',
  description:
    'An unexpected error occurred. If the issue persists, please contact our support team for assistance.',
};

export const NO_AGENCY_FOUND: NotificationProps = {
  type: 'error',
  title: 'No Agency Found',
  description:
    'To start integrating your ATS, please add an agency to your account.',
};

export const NO_INTEGRATION_FOUND: NotificationProps = {
  type: 'error',
  title: 'Integration Not Found',
  description:
    "We couldn't find any existing integration for this agency. Please provide the required credentials to begin the integration process.",
};

export const VERIFICATION_FAILED: NotificationProps = {
  type: 'error',
  title: 'Verification Failed',
  description:
    'We were unable to verify the integration for this agency. Please provide valid credentials to initiate the integration process.',
};

export const VERIFICATION_SUCCESS: NotificationProps = {
  type: 'success',
  title: 'Verification Successful',
  description:
    'The integration for this agency has been successfully verified. You may now start configuring your integration.',
};

export const INVALID_CREDENTIALS: NotificationProps = {
  type: 'error',
  title: 'Invalid Credentials',
  description:
    'The credentials provided are invalid. Please review and try again.',
};

export const COPIED_TO_CLIPBOARD: NotificationProps = {
  type: 'success',
  title: 'Copied to Clipboard',
};

export const ACTIVATION_FAILED: NotificationProps = {
  type: 'error',
  title: 'Activation Failed',
  description:
    "We couldn't activate the integration. Please ensure your credentials are verified before activating.",
};

export const ACTIVATION_SUCCESS: NotificationProps = {
  type: 'success',
  title: 'Activation Successful',
  description:
    'The integration has been successfully activated. You may now start configuring your integration.',
};

export const DEACTIVATION_SUCCESS: NotificationProps = {
  type: 'success',
  title: 'Deactivation Successful!',
  description:
    'The integration has been successfully deactivated. You cannot interact with Bullhorn until you reactivate the integration.',
};

export const UPDATE_FAILED: NotificationProps = {
  type: 'error',
  title: 'Update Failed',
  description: "We couldn't update your credentials. Please try again.",
};

export const BH_CREATION_FAILED: NotificationProps = {
  type: 'error',
  title: 'Profile Creation Failed',
  description: "We couldn't sync profile to your agency. Please try again.",
};
export const SYNC_TYPE: NotificationProps = {
  type: 'success',
  title: 'User Sync Type Changed',
  description: 'The user sync type has been updated successfully.',
};

export const ASSIGN_COMPETENCY: NotificationProps = {
  type: 'success',
  title: 'Success!',
  description: 'Competencies assigned successfully.',
};
