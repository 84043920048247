import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { AgencyResponse } from '@/utils/interface';
import Cookies from 'js-cookie';

import { notify } from '@/components/molecules';
import { NO_AGENCY_FOUND } from '@/components/atoms';
import { getAgency } from '@/services/agencyService';

interface AgencyContextProps {
  isLoading?: boolean;
  agency: AgencyResponse | null;
  setAgency: (data: AgencyResponse) => void;
}

const AgencyContext = createContext<AgencyContextProps | undefined>(undefined);

export const useAgency = (): AgencyContextProps => {
  const context = useContext(AgencyContext);
  if (!context) {
    throw new Error('useAgency must be used within an AgencyProvider');
  }
  return context;
};

interface AgencyProviderProps {
  children: ReactNode;
}

export const AgencyProvider = ({ children }: AgencyProviderProps) => {
  const [agency, setAgency] = useState<AgencyResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchAgency = async () => {
    try {
      const accessToken = Cookies.get('accessToken');
      if (accessToken) {
        const fetchedAgency = await getAgency(accessToken);
        if (fetchedAgency) setAgency(fetchedAgency);
      }
    } catch (error) {
      notify(NO_AGENCY_FOUND);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAgency();
  }, []);

  return (
    <AgencyContext.Provider value={{ agency, isLoading, setAgency }}>
      {children}
    </AgencyContext.Provider>
  );
};
