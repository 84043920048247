import { Button, Input, Label } from '@/components/atoms';

export const BullhornConfiguration: React.FC = () => {
  const isVerified = true;
  const isIntegrationActivated = false;
  return (
    <>
      <div className="flex items-center mb-3">
        <Label
          className="mr-2 font-semibold"
          text={'Activate HSH-BH Integration'}
        />

        <div
          className={`relative w-6 h-3 transition-colors duration-200 ease-in-out rounded-full cursor-pointer ${
            isIntegrationActivated ? 'bg-blue-500' : 'bg-gray-300'
          }`}
        >
          <Input
            type={'checkbox'}
            disabled={isVerified ? false : true}
            checked={isIntegrationActivated}
            className="opacity-0 absolute w-0 h-0"
          />
          <div
            className={`absolute left-0 top-0 w-3 h-3 transition-transform duration-200 ease-in-out transform ${
              isIntegrationActivated ? 'translate-x-full' : 'translate-x-0'
            } bg-white rounded-full`}
          ></div>
        </div>
      </div>
      <div className="md:col-span-1 mb-3 mt-6">
        <Label htmlFor={'username'} text={'Custom Tab URL'} />
        <Input
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          disabled
          type="text"
          placeholder="Custom Tab URL"
        />
      </div>
      <div className="md:col-span-1">
        <Label text={'Custom Tab Token'} />
        <Input
          className="mt-1 p-2 border border-gray-300 rounded-md w-full"
          disabled
          type="text"
          placeholder="Custom Tab Token"
        />
      </div>
      <div className="flex items-start mt-5">
        <Button
          className="flex h-10 w-[150px] items-center justify-center rounded-md bg-blue-800 px-5 text-center text-sm font-medium text-white disabled:cursor-not-allowed disabled:opacity-70"
          text={'Copy'}
        />
      </div>
    </>
  );
};
