import React, { useState } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

const Input: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { type, placeholder, className, disabled, ...rest },
  ref,
) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputType = type === 'password' && showPassword ? 'text' : type;

  return (
    <div className="relative">
      <input
        {...rest}
        type={inputType}
        placeholder={placeholder}
        className={className}
        disabled={disabled}
        ref={ref}
        required
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-0 top-0 mt-3 mr-3 text-gray-500 hover:text-blue-500 focus:outline-none"
        >
          <i
            className={
              showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
            }
          ></i>
        </button>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
