export const LoginHeader: React.FC = () => (
  <header className="h-32">
    <div className="h-32">
      <img
        className="relative left-0 top-0 w-[700px]"
        alt="top_blue"
        src="/assets/top_blue.png"
      />
      <a href="/" className="absolute left-5 top-5 z-10">
        <img src="/assets/logoHSH.png" />
      </a>
    </div>
  </header>
);
