/* eslint-disable @typescript-eslint/indent */
import { Button, Label } from '@/components/atoms';
import { SyncType } from '@/utils/enums';
import { useCallback, useState } from 'react';

export const BullhornSyncType: React.FC = () => {
  const [userSyncType, setUserSyncType] = useState<string>(SyncType.NONE);

  const handleUserSyncTypeChange = useCallback((value: string) => {
    setUserSyncType(value);
  }, []);

  const renderSyncOptions = () => (
    <div className="mt-6">
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        {Object.values(SyncType).map((type) => (
          <label key={type} htmlFor={type} className="cursor-pointer">
            <input
              type={'radio'}
              placeholder={''}
              id={type}
              name="userSyncType"
              value={type}
              checked={userSyncType === type}
              onChange={(e) => handleUserSyncTypeChange(e.target.value)}
            />
            &nbsp;
            {type === SyncType.NONE
              ? 'None'
              : `Sync ${
                  type === SyncType.HSH_TO_BH ? 'HSH to BH' : 'BH to HSH'
                }`}
          </label>
        ))}
      </div>
    </div>
  );

  const renderSyncDetails = () => {
    const syncDetails = {
      none: (
        <div className="mt-5 text-md">
          <strong>
            No automatic sync of users will occur between HSH and Bullhorn.
          </strong>
          <br />
          To sync users manually, use the Custom Tab iFrame tool.
        </div>
      ),
      hsh_to_bh: (
        <div className="mt-5 text-md">
          <strong>
            Automatically updates the HSH User list to match with
            Bullhorn&#39;s.
          </strong>
        </div>
      ),
      bh_to_hsh: (
        <div className="mt-5 text-md">
          <strong>
            Automatically updates the Bullhorn User list to match with
            HSH&#39;s.
          </strong>
        </div>
      ),
    };

    return (
      <div className="mt-5 text-sm">
        {syncDetails[userSyncType as keyof typeof syncDetails]}
      </div>
    );
  };

  return (
    <>
      <Label text={'Custom Sync Type'} className="font-semibold" />
      {renderSyncOptions()}
      {renderSyncDetails()}
      <div className="flex items-start mt-5">
        <Button
          className="flex h-10 w-[150px] items-center justify-center rounded-md bg-blue-800 px-5 text-center text-sm font-medium text-white disabled:cursor-not-allowed disabled:opacity-70"
          text={'Save Changes'}
        />
      </div>
    </>
  );
};
