import { useAgency } from '@/hooks/useAgency';
import { Menu, Transition } from '@headlessui/react';

import { Fragment } from 'react/jsx-runtime';
import { Label } from '../label/Label';

export const ProfileMenu: React.FC = () => {
  const { agency } = useAgency();
  return (
    <div className="noprint flex flex-1 justify-between px-4 py-3 sm:px-6">
      <div className="ml-auto flex items-center gap-6">
        <div className="w-30 md:w-18 text-center">
          <Label htmlFor={''} text={'ATS Integration for'} />
          <Label htmlFor={''} text={agency ? agency.name : 'HSH'} />
        </div>
        <Menu as="div" className="relative flex-shrink-0">
          <div>
            <Menu.Button className="relative flex h-[46px] w-[46px] items-center justify-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <span className="sr-only">Open user menu</span>
              <i className="fa-solid fa-user fa-2x text-gray-500"></i>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <span className="block px-2 py-1 text-center text-sm text-gray-700 cursor-pointer">
                Sign out
              </span>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};
