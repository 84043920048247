import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';

import {
  Button,
  GENERIC_ERROR,
  INCORRECT_LOGIN,
  Input,
  LOGIN_SUCCESS,
  Spinner,
} from '@/components/atoms';

import { notify } from '..';

import { handleAuthAgency, handleAuthATS } from '@/services/authService';
import { UserRole } from '@/utils/types';
import { AuthCredentials } from '@/utils/interface';

import { getAgency } from '@/services/agencyService';
import { useAgency } from '@/hooks/useAgency';

type FormData = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
  } = useForm<FormData>();

  const [step, setStep] = useState<UserRole>(() => {
    const storedStep = history.state?.loginStep;
    return storedStep ? storedStep : UserRole.AGENCY;
  });
  const [agencyToken, setAgencyToken] = useState<string>(() => {
    return Cookies.get('accessToken') || '';
  });

  const [loading, setLoading] = useState<boolean>(false);
  const { setAgency } = useAgency();
  const navigate = useNavigate();

  function isAxiosError(error: unknown): error is AxiosError {
    return (error as AxiosError).isAxiosError !== undefined;
  }
  const handleLogin = async (data: FormData) => {
    try {
      setLoading(true);

      if (step === UserRole.AGENCY) {
        const response: AuthCredentials = await handleAuthAgency(
          data.email,
          data.password,
        );
        setAgencyToken(response.accessToken as string);
        Cookies.set('accessToken', response.accessToken as string);

        const agencyData = await getAgency(response.accessToken as string);
        if (agencyData) {
          setAgency(agencyData);
        }

        setStep(UserRole.ATS);
      } else if (step === UserRole.ATS) {
        const response: AuthCredentials = await handleAuthATS(
          data.email,
          data.password,
          agencyToken,
        );

        if (response) {
          navigate('/bullhorn');
          notify(LOGIN_SUCCESS);
        }
      }
      reset();
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response && error.response.status === 401) {
          notify(INCORRECT_LOGIN);
        } else {
          notify(GENERIC_ERROR);
        }
      } else {
        notify(GENERIC_ERROR);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (data: FormData) => {
    setLoading(true);
    handleLogin(data);
  };

  useEffect(() => {
    if (isSubmitSuccessful && !loading) {
      reset();
    }
  }, [isSubmitSuccessful, loading, reset]);

  useEffect(() => {
    history.replaceState({ ...history.state, loginStep: step }, '');
  }, [step]);

  return (
    <>
      <h1 className="sm:bold mt-6 text-2xl font-medium text-gray-900">
        Sign in with your {step === UserRole.AGENCY ? 'Agency' : 'ATS'} account.
      </h1>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-16 flex w-full flex-col"
      >
        <Input
          {...register('email')}
          type="email"
          placeholder="Email"
          className="w-full rounded-t-lg border border-gray-300 p-2"
          disabled={loading}
        />
        <div className="relative">
          <Input
            {...register('password')}
            type={'password'}
            placeholder="Password"
            className="w-full rounded-b-lg border border-t-0 border-gray-300 p-2"
            disabled={loading}
          />
        </div>

        <div className="flex flex-row justify-between">
          <a
            className={`mt-2 flex self-end text-sm text-blue-800 underline cursor-pointer ${
              step === UserRole.AGENCY ? 'opacity-0' : ''
            }`}
          >
            Sign out
          </a>

          <a
            href="/forgot"
            className="mt-2 flex self-end text-sm text-blue-800 underline"
          >
            Forgot your password?
          </a>
        </div>

        <div className="flex items-center justify-center">
          <Button
            type="submit"
            text={loading ? <Spinner /> : 'Sign In'}
            className="mt-10 flex h-10 w-full items-center justify-center rounded-md bg-blue-800 px-5 text-center text-sm font-medium text-white disabled:cursor-not-allowed disabled:opacity-70"
          />
        </div>
      </form>
    </>
  );
};
