import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import '../styles/global.css';
import { LoginPage, Dashboard } from '@/components/pages';
import PrivateRoute from './privateRoute';
import { BullhornPage } from '@/components/pages/bullhorn/BullhornPage';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route
          path="/bullhorn"
          element={<PrivateRoute element={<BullhornPage />} />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
