import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const isAuthenticated = !!Cookies.get('accessToken');
  return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute;
