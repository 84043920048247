import React, { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useAgency } from '@/hooks/useAgency';

interface Props {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (value: boolean) => void;
  navigationOptions: NavigationItem[];
}

export type NavigationItem = {
  current: boolean;
  name: string;
  href?: string;
  icon?: any;
};

const Navbar: React.FC<Props> = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  navigationOptions,
}) => {
  const { agency } = useAgency();

  const agencyLogo = `${process.env.REACT_APP_AGENCY_LOGO_URL}${agency?.logo}`;

  return (
    <>
      <div
        className={
          'noprint hidden w-28 overflow-y-auto md:block bg-custom-blue'
        }
      >
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            <img
              src={agency ? agencyLogo : '/assets/logo.svg'}
              alt="HSH"
              width={50}
              height={50}
              className="rounded"
            />
          </div>
        </div>

        <div className="flex w-full flex-col items-center py-6 p">
          {navigationOptions.map((item) => (
            <Link
              key={item.name}
              className={clsx(
                item.current
                  ? 'bg-blue-800 text-white'
                  : 'text-blue-100 hover:bg-blue-800 hover:text-white',
                'group flex w-[95px] mt-1 cursor-pointer flex-col items-center justify-center rounded-md p-3 text-center text-xs font-medium',
              )}
              to={item.href || ''}
            >
              <div className="flex flex-col items-center">
                <img
                  src={item.icon}
                  alt="HSH"
                  width={50}
                  className="cursor-pointer rounded mb-2"
                />
                <span>{item.name}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-1 flex-col bg-custom-blue pb-4 pt-5">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-1 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <i className="fa-solid fa-xmark"></i>

                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="relative flex flex-shrink-0 items-center px-4">
                  <img
                    width={33}
                    height={30}
                    src="/assets/logo.svg"
                    alt="HSH"
                  />
                </div>

                <div className="mt-10 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {navigationOptions.map((item) => (
                        <Link
                          key={item.name}
                          className={clsx(
                            item.current
                              ? 'bg-blue-800 text-white'
                              : 'text-blue-100 hover:bg-blue-800 hover:text-white',
                            'group flex items-center space-x-2 rounded-md px-3 py-2 text-center text-sm font-medium',
                          )}
                          to={item.href || ''}
                        >
                          <div className="flex flex-col items-center">
                            <img
                              src={item.icon}
                              alt="HSH"
                              width={50}
                              className="cursor-pointer rounded mb-2"
                            />
                          </div>
                          <span>{item.name} Integration</span>
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Navbar;
