import { BullhornIntegration } from '@/components/organisms';
import IntegrationPageLayout from '@/components/templates/layout/IntegrationPageLayout';

export const BullhornPage: React.FC = () => {
  return (
    <IntegrationPageLayout>
      <BullhornIntegration />
    </IntegrationPageLayout>
  );
};
