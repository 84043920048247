export const BullhornGuide: React.FC = () => {
  return (
    <div>
      <p>
        <strong>Link HSH with Bullhorn</strong>
        <br />
        <div className="mt-5">
          To seamlessly integrate HSH with Bullhorn, carefully follow these
          steps:
        </div>
      </p>
      <ol className="list-decimal pl-4 mt-2 text-md ">
        <li>
          <strong>Copy the URL</strong> from the provided screen.
        </li>
        <li>
          <strong>Login to Bullhorn</strong> and open the <strong>Menu</strong>{' '}
          bar on the left side of the screen.
        </li>
        <li>
          Select <strong>Admin</strong>.
        </li>
        <li>
          Select <strong>View Layout</strong>.
        </li>
        <li>
          From the <strong>Field Map Entity</strong> dropdown, choose Candidate.
        </li>
        <li>
          Go to <strong>Custom Tabs</strong>.
        </li>
        <li>
          Fill in the following details: <strong>Name, URL,</strong> and{' '}
          <strong>User Types</strong>.
        </li>
        <li>
          Once completed, click <strong>Save</strong>.
        </li>
      </ol>
    </div>
  );
};
