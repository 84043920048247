import React from 'react';

import { useNavigate } from 'react-router-dom';

import { notify } from '@/components/molecules';
import { LOGOUT_SUCCESS } from '..';
import Cookies from 'js-cookie';

export const UnderConstruction: React.FC = () => {
  const navigate = useNavigate();

  const handleATSLogout = () => {
    navigate('/');
    Cookies.remove('accessToken');
    notify(LOGOUT_SUCCESS);
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white p-8 rounded-md mb-[100px] shadow-md text-center">
        <h1 className="text-3xl font-bold mb-4">Under Construction</h1>
        <p className="text-gray-600 mb-6">
          We&#39;re working on something awesome! Please check back later.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
            onClick={handleATSLogout}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleATSLogout();
              }
            }}
            role="button"
            tabIndex={0}
            className="cursor-pointer rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
  hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
  focus-visible:outline-blue-600"
          >
            Logout
          </a>

          <a
            href="mailto:support@hs-hire.com?subject=Report%20Issue"
            className="text-sm font-semibold text-gray-900"
          >
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </div>
  );
};
