import React from 'react';
import toast, { Toast } from 'react-hot-toast';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@/components/atoms';

export const notify = ({
  type = 'success',
  title,
  description,
  duration = 10000,
}: {
  type: 'success' | 'error';
  title?: React.ReactNode;
  description?: React.ReactNode;
  duration?: number;
}) => {
  let displayTitle: React.ReactNode;
  let displayDescription: React.ReactNode = description || '';
  let color = '';
  let icon: React.ReactElement<IconDefinition> | null = null;

  switch (type) {
    case 'error':
      color = 'text-red-500';
      icon = <i className="fa-solid fa-circle-exclamation"></i>;
      displayTitle = title || <>Something went wrong</>;
      break;
    case 'success':
    default:
      color = 'text-green-500';
      icon = <i className="fa-solid fa-circle-check"></i>;
      displayTitle = title || 'Success!';
      break;
  }

  const NotificationContent = ({ t }: { t: Toast }) => (
    <div className="flex gap-3">
      <div className={color}>{icon}</div>
      <div className="">
        <p className="text-sm font- text-gray-900">{displayTitle}</p>
        {displayDescription && (
          <p className="mt-1 text-sm medium  text-gray-500">
            {displayDescription}
          </p>
        )}
      </div>
      <div className="">
        <Button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500"
          onClick={() => toast.remove(t.id)}
          icon="fa-solid fa-xmark"
        />
      </div>
    </div>
  );

  return toast((t) => <NotificationContent t={t} />, {
    duration,
    onClosed: () => {
      toast.remove();
    },
  } as Partial<Pick<Toast, 'duration' | 'id' | 'icon' | 'ariaProps' | 'className' | 'style' | 'position' | 'iconTheme'>>);
};
