import { LoginFooter, LoginHeader, LoginOnBoard } from '@/components/atoms';
import { Login } from '@/components/molecules';

export const LoginPage = () => {
  return (
    <div className="min-h-screen antialiased bg-custom-white">
      <div className="flex flex-col">
        <LoginHeader />
        <section className="flex flex-wrap items-center justify-center gap-10">
          <img
            src="/assets/LoginBG.png"
            alt="loginBG"
            width={800}
            className="mt-11"
          />
          <div className="flex w-full flex-col items-center rounded-xl bg-white px-10 py-20 xl:w-1/3">
            <LoginOnBoard />
            <img
              width={50}
              height={45}
              alt={'Logo'}
              src="/assets/logo.svg"
              className="sm:h-16 sm:w-16"
            />
            <Login />
          </div>
        </section>
        <LoginFooter />
      </div>
    </div>
  );
};
