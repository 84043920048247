export const LoginOnBoard: React.FC = () => (
  <div className="mb-4 rounded-md bg-blue-50 p-4">
    <div className="flex">
      <div className="flex-shrink-0"></div>
      <div className="ml-3">
        <h3 className="text-sm font-medium text-blue-800">
          <strong>
            {' '}
            Welcome to the New Healthcare Staffing Hire - ATS Integration
            Platform!{' '}
          </strong>
        </h3>
        <div className="mt-2 text-sm text-blue-700">
          We appreciate your presence on our platform. <br /> To initiate the
          ATS integration process, kindly log in using your{' '}
          <strong>Agency Admin</strong> and <strong>ATS Integration </strong>{' '}
          role credentials.
          <p className=" mt-5 text-sm font-medium text-blue-800">
            <strong>
              Best regards, <br />
              The Healthcare Staffing Hire Team
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
);
