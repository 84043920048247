import { BullhornGuide } from '@/components/atoms';

import {
  BullhornChecklist,
  BullhornConfiguration,
  BullhornCredentials,
  BullhornSyncType,
} from '@/components/molecules';

export const BullhornIntegration: React.FC = () => {
  return (
    <>
      <div className="flex-1">
        <h1 className="noprint text-2xl font-medium text-blue-800">
          Bullhorn Integration Setup
        </h1>
        <div className="mt-6 rounded-lg p-6 bg-white md:w-[750px]">
          <BullhornGuide />
          <hr className="my-3 border-t border-gray-300" />
          <BullhornCredentials />
        </div>
      </div>

      <div className="flex-1">
        <h1 className="noprint text-2xl font-medium text-blue-800">&nbsp;</h1>
        <div className="mt-6 rounded-lg p-6 bg-white md:w-[750px]">
          <BullhornConfiguration />
          <hr className="my-5 border-t mb-3 border-gray-300" />
          <BullhornChecklist />
          <hr className="my-5 border-t mb-3 border-gray-300" />
          <BullhornSyncType />
        </div>
      </div>
    </>
  );
};
